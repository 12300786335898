import { SVGProps } from "./types";

export const OfficeBuildingUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.4444 17V4.55556C15.4444 3.69645 14.748 3 13.8889 3H6.11111C5.252 3 4.55556 3.69645 4.55556 4.55556V17M15.4444 17L17 17M15.4444 17H11.5556M4.55556 17L3 17M4.55556 17H8.44444M7.66667 6.11109H8.44444M7.66667 9.2222H8.44444M11.5556 6.11109H12.3333M11.5556 9.2222H12.3333M8.44444 17V13.1111C8.44444 12.6815 8.79267 12.3333 9.22222 12.3333H10.7778C11.2073 12.3333 11.5556 12.6815 11.5556 13.1111V17M8.44444 17H11.5556"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const OfficeBuildingFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.75 4.75C4.75 3.7835 5.5335 3 6.5 3H13.5C14.4665 3 15.25 3.7835 15.25 4.75V15.25C15.7332 15.25 16.125 15.6418 16.125 16.125C16.125 16.6082 15.7332 17 15.25 17H12.75C12.1977 17 11.75 16.5523 11.75 16V14.375C11.75 13.8918 11.3583 13.5 10.875 13.5H9.125C8.64175 13.5 8.25 13.8918 8.25 14.375V16C8.25 16.5523 7.80228 17 7.25 17H4.75C4.26675 17 3.875 16.6082 3.875 16.125C3.875 15.6418 4.26675 15.25 4.75 15.25V4.75ZM7.375 5.625H9.125V7.375H7.375V5.625ZM9.125 9.125H7.375V10.875H9.125V9.125ZM10.875 5.625H12.625V7.375H10.875V5.625ZM12.625 9.125H10.875V10.875H12.625V9.125Z"
            fill="currentColor"
        />
    </svg>
);
