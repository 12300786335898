export const isQRCheckin = (centreId?: string | string[]): boolean =>
    !!getQRCheckinProps(centreId);

type QRCheckinPageProps = { name: string; image: string };
export const getQRCheckinProps = (
    centreId?: string | string[],
): undefined | QRCheckinPageProps => {
    if (Array.isArray(centreId)) centreId = centreId[0];
    if (!centreId) return undefined;
    return pageProps[centreId];
};
const pageProps: Record<string, QRCheckinPageProps> = {
    "01DN1X7MV10B0DK6844N2YPFKZ": {
        name: "Forum Optimum Badminton Centre",
        image: "/images/optimum.png",
    },
    ckxk7mj3b00vy07amj6sqjbnr: {
        name: "Akademi Badminton Malaysia",
        image: "/images/bamlogo.png",
    },
    "01CC9QN74J9J0FNYGNSRTS83XG": {
        name: "Courtsite Secret Base",
        image: "/images/optimum.png",
    },
    cky2s6lbg000009f5x7ipwk8j: {
        name: "Neslo Centre",
        image: "/images/bamlogo.png",
    },
};
