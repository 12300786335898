import { SVGProps } from "./types";

export const CheckUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 11L7 15L17 5"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CheckCircleFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM13.2437 8.86872C13.5854 8.52701 13.5854 7.97299 13.2437 7.63128C12.902 7.28957 12.348 7.28957 12.0063 7.63128L9.125 10.5126L7.99372 9.38128C7.65201 9.03957 7.09799 9.03957 6.75628 9.38128C6.41457 9.72299 6.41457 10.277 6.75628 10.6187L8.50628 12.3687C8.84799 12.7104 9.40201 12.7104 9.74372 12.3687L13.2437 8.86872Z"
            fill="currentColor"
        />
    </svg>
);
