import * as Popover from "@radix-ui/react-popover";
import { User } from "firebase/auth";
import {
    CheckUnfilled,
    LogoutRightUnfilled,
    MenuAlt1Filled,
    OfficeBuildingUnfilled,
    UserFilled,
    UserUnfilled,
} from "./Icons";
import Link from "next/link";
import { Image } from "lib/imgproxy";
import { isQRCheckin } from "lib/featureflag";
import { cva, VariantProps } from "class-variance-authority";

const userMenuVariants = cva(
    "h-full cursor-pointer items-center gap-2 border-none bg-white outline-none",
    {
        variants: {
            visibility: {
                all: "flex",
                onlyDesktop: "hidden md:flex",
                onlyMobile: "md:hidden flex",
            },
        },
        defaultVariants: { visibility: "all" },
    },
);
export const UserMenu = (
    props: {
        user: User;
        tenantId?: string;
        onLogout?: () => void;
    } & VariantProps<typeof userMenuVariants>,
): JSX.Element => (
    <Popover.Root>
        <Popover.Trigger
            className={userMenuVariants({ visibility: props.visibility })}
        >
            <div className="relative size-8 overflow-hidden rounded-full">
                {props.user.photoURL ? (
                    <Image
                        key={props.user.uid}
                        alt={"Your profile photo"}
                        src={props.user.photoURL}
                        layout="fill"
                    />
                ) : (
                    <UserFilled className="size-full bg-blue-grey-50 text-primary" />
                )}
            </div>
            <MenuAlt1Filled className="size-7 text-blue-grey" />
        </Popover.Trigger>
        <Popover.Content className="z-10 divide-x-0 divide-y divide-solid divide-blue-grey-50 rounded border border-solid border-blue-grey-50 bg-white p-4 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95">
            <div className="typography-sub flex flex-col gap-1 pb-2">
                <Link href="/user">
                    <a className="flex items-center gap-2 text-blue-grey-900">
                        <UserUnfilled className="size-4" />
                        My Profile
                    </a>
                </Link>
                <Link href="/organisation">
                    <a className="flex items-center gap-2 text-blue-grey-900">
                        <OfficeBuildingUnfilled className="size-4" />
                        My Organisations
                    </a>
                </Link>
                {isQRCheckin(props.tenantId) && (
                    <Link
                        href={{
                            pathname: "/organisation/[orgID]/checkin",
                            query: { orgID: props.tenantId },
                        }}
                    >
                        <a className="flex items-center gap-2 text-blue-grey-900">
                            <CheckUnfilled className="size-4" />
                            Check-in Tool
                        </a>
                    </Link>
                )}
            </div>
            <div className="pt-2">
                <button
                    type="button"
                    className="typography-sub flex w-full cursor-pointer items-center gap-2 border-none bg-transparent p-0 text-blue-grey-900 transition hover:text-primary"
                    onClick={props.onLogout}
                >
                    <LogoutRightUnfilled className="size-4" />
                    Log out
                </button>
            </div>
        </Popover.Content>
    </Popover.Root>
);
