import { SVGProps } from "./types";

export const UserUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.1111 6.11111C13.1111 7.82933 11.7182 9.22222 9.99999 9.22222C8.28177 9.22222 6.88888 7.82933 6.88888 6.11111C6.88888 4.39289 8.28177 3 9.99999 3C11.7182 3 13.1111 4.39289 13.1111 6.11111Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.99999 11.5556C6.9931 11.5556 4.55554 13.9931 4.55554 17H15.4444C15.4444 13.9931 13.0069 11.5556 9.99999 11.5556Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const UserFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 8.6C11.5464 8.6 12.8 7.3464 12.8 5.8C12.8 4.2536 11.5464 3 10 3C8.45361 3 7.20001 4.2536 7.20001 5.8C7.20001 7.3464 8.45361 8.6 10 8.6Z"
            fill="currentColor"
        />
        <path
            d="M3.46667 17C3.46667 13.3917 6.39175 10.4667 10 10.4667C13.6083 10.4667 16.5333 13.3917 16.5333 17H3.46667Z"
            fill="currentColor"
        />
    </svg>
);
